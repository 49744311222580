import { TableRow, TableCell, Stack, IconButton } from "@mui/material";
import Iconify from "../../../core/Iconify";
import { ItemsEntity } from "../../../models/POSModel/IBillList";
import { ProductServices } from "../../../services/ProductServices";
import { useState, useEffect, useRef } from "react";
import useBillListStore from "../../../store/POS/useBillListStore";
// import { IItemList } from "../../../models/POSModel/IItemList";

// ----------------------------------------------------------------------
interface IProps {
  data: ItemsEntity;
  index: number;
  billNo?: number;
}

// ----------------------------------------------------------------------
const TRow = ({ data, index, billNo }: IProps) => {
  const [loader, setLoader] = useState(false);

  const { billList, setBillList } = useBillListStore((state) => ({
    billList: state.data,
    setBillList: state.setData,
  }));

  const lastRowRef = useRef<HTMLTableRowElement | null>(null);

  const removeItemHandler = async () => {
    try {
      setLoader(true);
      const response = await ProductServices.removeItemFromBillApi(
        data.productId._id,
        data.quantity,
        data.rate,
        billList?.billNo
      );
      if (response.status === 200 && response.data) {
        setBillList(response.data.bill);
      }
    } catch (error) {
      console.error("Error fetching products", error);
      alert("Error in remove ");
    } finally {
      setLoader(false);
    }
  };

  // Auto-scroll to the last row when a new item is added
  useEffect(() => {
    if (
      billList?.items &&
      index === billList.items.length - 1 &&
      lastRowRef.current
    ) {
      lastRowRef.current.scrollIntoView({ behavior: "smooth" });
      // lastRowRef.current.focus();
    }
  }, [billList, index]);

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        ref={
          billList?.items && index === billList.items.length - 1
            ? lastRowRef
            : null
        }
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
        tabIndex={-1} // To make it focusable
      >
        <TableCell align="left">#{index + 1}</TableCell>
        <TableCell align="left">{data.productId.name}</TableCell>
        <TableCell align="center">{data.quantity.toFixed(3)}</TableCell>

        <TableCell align="center">{data.rate.toFixed(2)}</TableCell>
        <TableCell align="center">
          {(data.quantity * data.rate).toFixed(2)}
        </TableCell>
        <TableCell>
          <Stack direction={"row"} gap={"10px"} justifyContent={"end"}>
            <IconButton color="success">
              <Iconify icon="mage:edit" />
            </IconButton>
            <IconButton color="error" onClick={removeItemHandler}>
              <Iconify icon="mingcute:delete-2-line" />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
