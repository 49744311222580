import { List } from "./core";
import ReactDOMServer from "react-dom/server";
import { useEffect, useMemo, useState } from "react";
import Add from "./core/Add";
import {
  Button,
  Grid,
  InputBase,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import H1 from "../../core/H1";
import { getTodayDate } from "../../hooks/getTodayDate";
import { BasicInput } from "../../core/BasicInput";
import useBillListStore from "../../store/POS/useBillListStore";
import { ProductServices } from "../../services/ProductServices";
import ConfirmTosted from "./core/ConfirmTosted";
import Receipt from "../../components/Bill/Bill"; // Import the Receipt component
import { IconBtn } from "../../core/Button";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { BillService } from "../../services/BillServices";
import useUnpaidBillStore from "../../store/POS/useUnpaidBillStore";
import { IPreviousBill } from "../../models/POSModel/IPreviousBill";
import { roundNumber } from "../../hooks/roundNumber";
import PrintBillModel from "./core/PrintBillModel";
import useNameStore from "../../store/POS/useNameStore";
import useMobileStore from "../../store/POS/useMobileStore";

function POSOrder() {
  const [open, setOpen] = useState(false);
  const { billList, setBillList } = useBillListStore((state) => ({
    billList: state.data,
    setBillList: state.setData,
  }));

  const [cashAmt, setCashAmt] = useState<number>(0);

  const { unpaidBill, setUnpaidBill } = useUnpaidBillStore((state) => ({
    unpaidBill: state.data,
    setUnpaidBill: state.setData,
  }));

  // const [customer, setCustomer] = useState("");
  // const [mobile, setMobile] = useState("");

  const { customer, setCustomer } = useNameStore((state) => ({
    customer: state.name,
    setCustomer: state.setName,
  }));

  const { mobile, setMobile } = useMobileStore((state) => ({
    mobile: state.mobile,
    setMobile: state.setMobile,
  }));

  const [previousBill, setPreviousBill] = useState<IPreviousBill | undefined>(
    undefined
  );

  const [selectBill, setSelectBill] = useState("");

  const selectBillChange = (event: SelectChangeEvent) => {
    setSelectBill(event.target.value as string);
  };

  const [addItemOpen, setAddItemOpen] = useState(false);

  const [openPrinter, setOpenPrinter] = useState(false);

  // const [open, setOpen] = useState(false);
  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };

  // Event handler for date change
  const isAddItemHandler = () => {
    setAddItemOpen(true);
  };

  // Event handler for date change
  const openModelHandler = () => {
    setOpenPrinter(!openPrinter);
  };

  const modelClose = () => {
    if (addItemOpen) {
      setAddItemOpen(false);
    }

    if (openPrinter) {
      setOpenPrinter(false);
    }
  };

  const [netTotal, setNetTotal] = useState(0);
  const [paymentMode, setPaymentMode] = useState<"UPI" | "Cash" | undefined>(
    undefined
  );
  const [loader, setLoader] = useState(false);

  const [active, setActive] = useState(false);
  const [date, setDate] = useState<string>(getTodayDate());
  const [input, setInput] = useState<number>(0);

  const printReceipt = (receiptHtml: string) => {
    const printWindow = window.open("", "_blank", "width=600,height=400");
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head><title>Print Receipt</title></head>
          <body>${receiptHtml}</body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
      // setTimeout(() => {
      //   // setTimeout(() => {
      //   // }, 500); // Allow some time for the print command to execute
      // }, 500);
    } else {
      console.error("Failed to open print window");
    }
  };

  const addBillPaymentHandler = async (paymentMode: "UPI" | "Cash") => {
    const data = {
      billNo: billList?.billNo ? billList.billNo : 0,
      paymentMode: paymentMode,
      customer_contact_number: mobile,
    };

    try {
      setLoader(true);
      const response = await ProductServices.addBillPaymentApi(data);
      if (response.status === 201 && response.data) {
        setNetTotal(0);
        setCustomer("");
        setMobile("");
        setInput(0);

        // Print the receipt
        const receiptHtml = ReactDOMServer.renderToString(
          <Receipt billPayment={response.data.billPayment} cashAmt={cashAmt} />
        );

        printReceipt(receiptHtml);
        setBillList(undefined);

        setTimeout(() => {
          setOpen(false);
        }, 5000);
      }
    } catch (error) {
      console.error("Error processing payment", error);
      alert("Error processing payment");
    } finally {
      setLoader(false);
    }
  };

  const getBillHandler = async (billNo?: number) => {
    try {
      setLoader(true);
      const response = await ProductServices.getBillApi(
        billNo ? billNo : input
      );
      if (response.status === 200 && response.data) {
        console.log("billList", response.data.billDetails);

        setBillList(response.data.billDetails);
      }
    } catch (error) {
      console.error("Error fetching products", error);
    } finally {
      setLoader(false);
    }
  };

  const getUnpaidBillApi = async (billNo?: number) => {
    try {
      setLoader(true);
      const response = await BillService.getUnpaidBillsAlpi();
      if (response.status === 200 && response.data) {
        setUnpaidBill(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching products", error);
    } finally {
      setLoader(false);
    }
  };

  const getPreviousBillApi = async (billNo?: number) => {
    try {
      setLoader(true);
      const response = await BillService.getPreviousBillsAlpi();
      if (response.status === 200 && response.data) {
        setPreviousBill(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching products", error);
    } finally {
      setLoader(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCashAmt(parseFloat(event.target.value));
  };

  useEffect(() => {
    getUnpaidBillApi();
    getPreviousBillApi();
  }, [billList]);

  useEffect(() => {
    if (selectBill !== "") {
      getBillHandler(+selectBill);
    }
  }, [selectBill]);

  useEffect(() => {
    if (billList) {
      setInput(billList.billNo);
      if (billList.items) {
        setNetTotal(
          billList.items.reduce(
            (total, product) => total + product.quantity * product.rate,
            0
          )
        );
      }
    }
  }, [billList]);

  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };
  const inputHandler = (value: string) => {
    setInput(+value);
  };

  const inputCustomerHandler = (value: string) => {
    setCustomer(value);
  };

  const inputMobileHandler = (value: string) => {
    setMobile(value);
  };

  useEffect(() => {
    if (paymentMode) {
      setOpen(true);
    } else {
      setOpen(false);
      setPaymentMode(undefined);
    }
  }, [paymentMode]);

  const onPaymentModelHandler = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenPrinter(false);
    setPaymentMode(undefined);
  };

  useEffect(() => {
    // if (billList && billList.items && billList.items?.length > 0) {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "F8" || (event.code === "F8" && !event.shiftKey)) {
        event.preventDefault(); // Prevent the default browser behavior for F5 key
        onPaymentModelHandler();
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // }
  }, [open]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "F10" || (event.code === "F10" && !event.shiftKey)) {
        event.preventDefault(); // Prevent the default browser behavior for F5 key
        openModelHandler();
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [openPrinter]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "F9" || (event.code === "F9" && !event.shiftKey)) {
        event.preventDefault(); // Prevent the default browser behavior for F5 key
        // openModelHandler();
        isAddItemHandler();
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [addItemOpen]);

  useEffect(() => {
    setCashAmt(0);
  }, [open]);

  const createNewBill = () => {
    setBillList(undefined);
    setInput(0);
    setNetTotal(0);
    setSelectBill("");
  };

  return (
    <>
      <Stack direction={"row"} px={3} justifyContent={"space-between"} py={3}>
        <H1 title="Generate Bill" />
        {/* <IconBtn/> */}
        <IconBtn title="New Bill" onClickHandler={() => createNewBill()} />
      </Stack>
      <Stack direction={"row"} px={3} py={0}>
        <Grid container spacing={1.5}>
          <Grid item lg={9}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack direction={"row"} gap={"20px "} alignItems={"center"}>
                <InputBase
                  type="date"
                  value={date}
                  disabled={true}
                  onChange={onDateChange}
                  sx={{
                    "& .MuiInputBase-input": {
                      position: "relative",
                      backgroundColor: "background.paper",
                      border: (theme) => `1px solid #ccc`,
                      px: 1,
                      py: 1,
                      borderRadius: 3,
                    },
                  }}
                />
                <BasicInput
                  width={"190px"}
                  Input={customer}
                  InputHandler={inputCustomerHandler}
                  placeHolder="Customer Name"
                />
                <BasicInput
                  width={"190px"}
                  Input={mobile}
                  InputHandler={inputMobileHandler}
                  placeHolder="Customer Mobile"
                />
                {/* <Button
                  variant="contained"
                  size="small"
                  sx={{ height: "40px" }}
                >
                  (F1) Add Customer
                </Button> */}
              </Stack>
              <Stack direction={"row"} gap={"20px "} alignItems={"center"}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ height: "40px" }}
                  onClick={isAddItemHandler}
                >
                  (F9) Check Price
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ height: "40px" }}
                  onClick={openModelHandler}
                >
                  (F10) Print Bill
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item lg={3}>
            <Stack
              direction={"row"}
              gap={"20px "}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <BasicInput
                width={"100%"}
                Input={input.toString()}
                InputHandler={inputHandler}
                placeHolder="POS Bill"
              />
              <Button
                variant="contained"
                size="small"
                sx={{ height: "40px" }}
                onClick={() => getBillHandler()}
              >
                (F3) Find
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction={"row"} px={3} py={0}>
        <Grid container spacing={1.5}>
          <Grid item lg={9}>
            <List />
          </Grid>
          <Grid item lg={3}>
            <Paper sx={{ width: "100%", height: "200px" }} elevation={3}>
              <Stack
                direction={"row"}
                gap={"20px "}
                justifyContent={"center"}
                height={"100%"}
                alignItems={"center"}
              >
                <div>
                  <Typography
                    gutterBottom
                    fontSize={"20px"}
                    textAlign={"center"}
                  >
                    Previous Bill
                  </Typography>
                  <Typography
                    fontSize={"20px"}
                    gutterBottom
                    textAlign={"center"}
                    fontWeight={"700"}
                  >
                    {/* 1545345 [123/232.00]  */}
                    Bill No : {previousBill?.billDetails?.billNo} <br />
                    {/* [ ₹{" "}
                    {roundNumber(
                      previousBill?.totalBillAmount
                        ? `${previousBill?.totalBillAmount}.00`
                        : 0
                    )}{" "}
                    / {previousBill?.paymentMode} ] */}
                    [ ₹ {roundNumber(previousBill?.totalBillAmount || 0)}
                    {".00"} / {previousBill?.paymentMode} ]
                  </Typography>
                  <Typography
                    fontSize={"20px"}
                    textAlign={"center"}
                    gutterBottom
                  >
                    <i>
                      Total Items : {previousBill?.billDetails.items?.length}
                    </i>
                  </Typography>
                </div>
              </Stack>
            </Paper>
            <Paper
              sx={{ width: "100%", height: "auto", mt: 1, p: 2 }}
              elevation={3}
            >
              <Typography variant="h4" sx={{ pb: 1 }} gutterBottom>
                Select Unpaid Bill :
              </Typography>
              <Stack direction={"row"} gap={"10px"} pb={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Bill
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectBill}
                    label="Select Bill"
                    onChange={selectBillChange}
                    sx={{ fontWeight: "900" }}
                  >
                    <MenuItem value="" sx={{ fontWeight: 700 }}>
                      <em>None</em>
                    </MenuItem>
                    {unpaidBill &&
                      unpaidBill.length > 0 &&
                      unpaidBill.map((data) => (
                        <MenuItem
                          key={data._id}
                          value={data.billNo}
                          sx={{ fontWeight: 700 }}
                        >
                          {data.billNo}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Stack>

              {netTotal > 0 && (
                <>
                  <div
                    style={{ border: "1px dashed #000", width: "100%" }}
                  ></div>

                  <Typography
                    sx={{
                      fontSize: "22px",
                      display: "flex",
                      justifyContent: "space-between",
                      fontWeight: 700,
                      pt: 1.5,
                    }}
                    // color={""}
                  >
                    Total : <i>{netTotal.toFixed(2)}</i>
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "22px",
                      display: "flex",
                      justifyContent: "space-between",
                      fontWeight: 700,
                      py: 1,
                    }}
                    // color={"primary"}
                  >
                    Round Off :{" "}
                    <i>
                      {" "}
                      {netTotal - roundNumber(netTotal) > 0 ? "-" : "+"}
                      {Math.abs(netTotal - roundNumber(netTotal)).toFixed(2)}
                    </i>
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "30px",
                      display: "flex",
                      justifyContent: "space-between",
                      fontWeight: 700,
                      pb: 1,
                    }}
                    color={"primary"}
                  >
                    <i>Net :</i> <i>₹{roundNumber(netTotal)}</i>
                  </Typography>
                  <Stack direction={"row"} gap={"10px"} pb={2}>
                    <Button
                      variant="outlined"
                      fullWidth
                      disabled={
                        billList?.items && billList.items.length > 0
                          ? false
                          : true
                      }
                      sx={{
                        fontWeight: 900,
                        border: "3px solid ",
                      }}
                      onClick={onPaymentModelHandler}
                    >
                      (F8)
                      <br />
                      Pay Now
                    </Button>
                  </Stack>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Stack>

      <ConfirmTosted
        open={open}
        title="Payment Mode"
        subTitle="Are you sure you got payment"
        callApiHandler={() =>
          addBillPaymentHandler(
            paymentMode === undefined ? "Cash" : paymentMode
          )
        }
        paymentMode={paymentMode === undefined ? "Cash" : paymentMode}
        amount={netTotal}
        cashAmt={cashAmt}
        handleInputChange={handleInputChange}
        handleClose={handleClose}
        setPaymentMode={(data) => setPaymentMode(data)}
      />

      <PrintBillModel
        open={openPrinter}
        title="Bill Print"
        subTitle="Here You can print an duplicate Bill"
        handleClose={handleClose}
      />

      <Add
        open={addItemOpen}
        activeHandler={activeHandler}
        handleClose={modelClose}
        onModelHandler={isAddItemHandler}
        title="Check Price"
        subTitle="Check an Product Price and More"
      />
    </>
  );
}

export default POSOrder;
