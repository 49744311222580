import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Contained from "../../../../core/Button/Contained";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import AllDriver from "../../../../core/AllDriver";
import { useNavigate } from "react-router-dom";
import AllProduct from "../../../../core/AllProduct";
// import { IProduct } from "../../../../models/IProduct";
import Iconify from "../../../../core/Iconify";
import { IProduct } from "../../../../models/POSModel/IProduct";
import { ProductServices } from "../../../../services/ProductServices";
import useBillListStore from "../../../../store/POS/useBillListStore";
import { IState } from "../../../../models/IState";
import Tosted from "../../../../core/Tosted";
import { CircularProgress } from "@mui/material";
import useWeightStore from "../../../../store/POS/useWeightStore";
import usePCSStore from "../../../../store/POS/usePCSStore";

// Extend the Navigator interface to include the serial API
interface NavigatorWithSerial extends Navigator {
  serial: {
    requestPort: () => Promise<SerialPort>;
  };
}

interface IProps {
  isCheckprice?: boolean;
}

// Extend the Window interface to include NavigatorWithSerial
interface WindowWithNavigator extends Window {
  navigator: NavigatorWithSerial;
}

// Extend the global window object
declare let window: WindowWithNavigator;

// TypeScript declaration for the SerialPort interface
interface SerialPort {
  readable: ReadableStream | null;
  writable: WritableStream | null;
  open: (options: { baudRate: number }) => Promise<void>;
  close: () => Promise<void>;
}

type SubmitData = {
  name: string;
  quantity: string;
};

interface IProductData {
  id: string;
  name: string;
}

interface AllProductActions {
  clearInput: () => void;
}

const Form = ({ isCheckprice }: IProps) => {
  const [isEditMode, setEditMode] = useState<boolean>(false);
  // const [weight, setWeight] = useState<string>("1.000");

  const { weight, setWeight } = useWeightStore((state) => ({
    weight: state.weight,
    setWeight: state.setWeight,
  }));

  const { PCS, setPCS } = usePCSStore((state) => ({
    PCS: state.PCS,
    setPCS: state.setPCS,
  }));

  const [weightUnit, setWeightUnit] = useState<"kG" | "PCS">("kG");
  // const [loader, setLoader] = useState(false);
  const [rate, setRate] = useState(0);
  const navigate = useNavigate();
  const [searchProduct, setSearchProduct] = useState<IProduct | null>(null);
  const [nameValid, setNameValid] = useState({
    isValid: false,
    message: "Fill the Name",
  });
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [loaderRate, setLoaderRate] = useState(false);

  const allProductRef = useRef<AllProductActions>(null);

  const handleClear = () => {
    allProductRef.current?.clearInput();
    setSearchProduct(null);
  };

  const [port, setPort] = useState<SerialPort | null>(null);

  const { billList, setBillList } = useBillListStore((state) => ({
    billList: state.data,
    setBillList: state.setData,
  }));

  // Add a ref for the scrollable container
  const formRef = useRef<HTMLFormElement>(null);

  // Add a ref for the last item in the list
  const lastItemRef = useRef<HTMLDivElement>(null);

  const productInputHandler = (data: IProduct | null) => {
    if (data?._id && data.name) {
      setSearchProduct(data);
      setNameValid({
        isValid: false,
        message: "Product is Required",
      });
    }
  };

  const connectSerialPort = async () => {
    try {
      const newPort = await window.navigator.serial.requestPort();
      await newPort.open({ baudRate: 9600 });
      setPort(newPort);
    } catch (error) {
      console.error("Failed to open serial port:", error);
    }
  };

  useEffect(() => {
    connectSerialPort();
  }, []);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeight(event.target.value);
  };

  const handlePCSChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPCS(event.target.value);
  };

  const handleUnitChange = (event: SelectChangeEvent<"kG" | "PCS">) => {
    setWeightUnit(event.target.value as "kG" | "PCS");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    setNameValid({
      isValid: searchProduct?._id ? false : true,
      message: "Product is Required",
    });
    // return;

    const qty = weightUnit === "PCS" ? +PCS : +weight;

    if (rate > 0 && nameValid.isValid === false && +qty > 0) {
      updateHandler(
        searchProduct?._id ? searchProduct?._id : "",
        weightUnit === "PCS" ? +PCS : +weight,
        rate
      );
    }
  };

  const updateHandler = async (
    productId: string,
    quantity: number,
    rate: number
  ) => {
    try {
      setState({
        ...state,
        loader: true,
      });
      const response = await ProductServices.addOrUpdateBillApi(
        productId,
        quantity,
        rate,
        billList?.billNo
      );
      if (response.status === 200 && response.data) {
        setRate(0);
        setPCS("");
        setSearchProduct(null);
        setBillList(response.data.data.bill);
        setEditMode(false);
        handleClear();

        let messages = response.data.data.bill.items;

        // if (Array.isArray(messages)) {
        //   messages = messages.reverse(); // Reverse the order of the messages
        // }

        if (messages) {
          setState({
            loader: false,
            tosted: true,
            message: messages,
            severity: "success",
          });
        } else {
          setState({
            loader: false,
            tosted: true,
            message: response.data.message,
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      console.error("Error fetching products", error);
      setRate(0);
      setState({
        loader: false,
        tosted: true,
        message: error.response.message,
        severity: "error",
      });
    } finally {
      setState({
        ...state,
        loader: false,
      });
    }
  };

  const getProductPrice = async (productId: string) => {
    setLoaderRate(true);
    try {
      const response = await ProductServices.getProductPriceApi(productId);
      if (response.status === 200 && response.data && response.data.data) {
        setRate(+response.data.data.sellingPrice);
        setWeightUnit(response.data.data.sellOn);
        setLoaderRate(false);
      }
    } catch (error) {
      console.error("Error fetching products", error);
      setRate(0);
      setLoaderRate(false);
    } finally {
      setLoaderRate(false);
    }
  };

  useEffect(() => {
    if (searchProduct?._id) {
      getProductPrice(searchProduct?._id ? searchProduct?._id : "");
    }
  }, [searchProduct]);

  useEffect(() => {
    if (!port?.readable) return;

    const textDecoder = new TextDecoderStream();
    const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
    const reader = textDecoder.readable.getReader();

    const readLoop = async () => {
      let buffer = "";
      try {
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            break;
          }
          buffer += value;
          const lines = buffer.split("\n");
          buffer = lines.pop() || ""; // Keep the incomplete line for the next iteration

          for (const line of lines) {
            const trimmedLine = line.trim();
            console.log(trimmedLine); // Logging the raw line for debugging

            if (/^[+-]?\d{3}\.\d{3}$/.test(trimmedLine)) {
              // setCurrentValue(trimmedLine);
              const number = parseFloat(trimmedLine);
              if (isEditMode === false) {
                setWeight(number.toFixed(3));
              }
            }
          }
        }
      } catch (error) {
        console.error("Error reading from serial port:", error);
      } finally {
        reader.releaseLock();
      }
    };

    readLoop();

    return () => {
      reader.cancel();
      readableStreamClosed.catch(() => {
        /* handle errors here */
      });
      port?.close();
    };
  }, [port]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "NumpadAdd" || (e.key === "+" && !e.shiftKey)) {
      // Check for "+" key without shift or the numpad "+" key
      e.preventDefault();
      handleSubmit(e as unknown as React.FormEvent<HTMLFormElement>); // Cast the event type to match handleSubmit's expected type
    }
  };

  const handleClose = () => {
    if (tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
        });
      }, 5000);
    }
  };
  const { loader, tosted, message, severity } = state;
  useEffect(() => {
    handleClose();
  }, [tosted]);

  // Scroll to the last added item when billList updates
  useEffect(() => {
    if (lastItemRef.current) {
      lastItemRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [billList]);
  return (
    <>
      <form
        ref={formRef} // Set the ref to the form element
        className="column"
        onSubmit={handleSubmit}
        onKeyDown={handleKeyDown}
      >
        <Stack direction="row" justifyContent="center" width="100%">
          <Stack
            component={Paper}
            elevation={1}
            my={2}
            sx={{
              border: "1px solid #f6f6f6",
              width: "250px",
              position: "relative",
            }}
          >
            {weightUnit !== "PCS" ? (
              <Box
                sx={{
                  position: "absolute",
                  height: "30px",
                  width: "100%",
                }}
              >
                <Stack
                  direction={"row"}
                  gap={"10px"}
                  px={1.5}
                  pt={1}
                  justifyContent={"space-between"}
                >
                  <IconButton color="primary" onClick={connectSerialPort}>
                    <Iconify icon="tabler:plug-connected" />
                  </IconButton>
                  <IconButton color="error" onClick={handleEditClick}>
                    <Iconify icon="ic:baseline-edit" />
                  </IconButton>
                </Stack>
              </Box>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1, p: 2 }}>
                <TextField
                  size="small"
                  value={PCS}
                  onChange={handlePCSChange}
                  type="number"
                  sx={{ flexGrow: 1 }}
                />
                <FormControl size="small" sx={{ width: "150px" }}>
                  <Select
                    value={weightUnit ? weightUnit : ""}
                    onChange={handleUnitChange}
                    displayEmpty
                    disabled
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="KG">Kg</MenuItem>
                    <MenuItem value="PCS">PCS</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}

            <Typography
              variant="h1"
              textAlign="center"
              component={"span"}
              sx={{
                py: 2,
                fontSize: "45px",
                fontWeight: 400,
              }}
            >
              {weightUnit == "PCS" ? PCS : weight}{" "}
              <Typography sx={{ fontSize: "20px", fontWeight: 700, pt: 1 }}>
                <i>{weightUnit.toUpperCase()}</i>
              </Typography>
            </Typography>
          </Stack>
        </Stack>

        {/* <AllProduct
          isFullWidth
          ref={allProductRef}
          inputHandler={productInputHandler}
        /> */}

        <AllProduct
          inputHandler={productInputHandler}
          ref={allProductRef}
          isFullWidth={true}
          isValid={nameValid.isValid}
        />

        {nameValid.isValid && (
          <Typography
            fontWeight={"600"}
            pl={2}
            pt={1}
            fontSize={"12px"}
            color={"error"}
          >
            {nameValid.isValid ? nameValid.message : ""}
          </Typography>
        )}

        <Stack direction={"row"} justifyContent={"space-between"} py={0.5}>
          <Typography sx={{ fontSize: "20px", fontWeight: 700, pt: 1 }}>
            <i>
              {weightUnit === "PCS" ? PCS : weight} {weightUnit}
            </i>
          </Typography>
          <Typography sx={{ fontSize: "20px", fontWeight: 700, pt: 1 }}>
            <i>X</i>
          </Typography>

          <Typography sx={{ fontSize: "20px", fontWeight: 700, pt: 1 }}>
            {loaderRate ? (
              <CircularProgress disableShrink size={27} />
            ) : (
              <i>
                ₹
                {typeof rate === "number" && !isNaN(rate)
                  ? rate.toFixed(2)
                  : "0.00"}
              </i>
            )}
          </Typography>

          <Typography sx={{ fontSize: "20px", fontWeight: 700, pt: 1 }}>
            <i>=</i>
          </Typography>
          <Typography
            sx={{ fontSize: "20px", fontWeight: 700, pt: 1 }}
            color={"primary"}
          >
            <i>₹{((weightUnit == "PCS" ? +PCS : +weight) * rate).toFixed(2)}</i>
          </Typography>
        </Stack>
        {isCheckprice && (
          <>
            <br />
            <Box textAlign={"center"} pt={0}>
              <Contained
                type="submit"
                variant="text"
                loader={state.loader}
                disabled={state.loader}
              >
                (₹{((weightUnit === "PCS" ? +PCS : +weight) * rate).toFixed(2)})
                <br />
                Add Item (+)
              </Contained>
            </Box>
          </>
        )}
      </form>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default Form;
